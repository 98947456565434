exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assegnazion-index-jsx": () => import("./../../../src/pages/Assegnazion/index.jsx" /* webpackChunkName: "component---src-pages-assegnazion-index-jsx" */),
  "component---src-pages-home-page-home-page-jsx": () => import("./../../../src/pages/HomePage/HomePage.jsx" /* webpackChunkName: "component---src-pages-home-page-home-page-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-index-jsx": () => import("./../../../src/pages/Project/index.jsx" /* webpackChunkName: "component---src-pages-project-index-jsx" */),
  "component---src-pages-siamo-page-index-jsx": () => import("./../../../src/pages/SiamoPage/index.jsx" /* webpackChunkName: "component---src-pages-siamo-page-index-jsx" */),
  "component---src-pages-territorio-page-index-jsx": () => import("./../../../src/pages/TerritorioPage/index.jsx" /* webpackChunkName: "component---src-pages-territorio-page-index-jsx" */)
}

